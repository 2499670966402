<template>
  <div class="-mt-6 text-xs mb-4 ">
    <router-link to="/">
      <span v-text="$t('label.home_link')" />
    </router-link>
    <span class="opacity-30 px-2">/</span>
    <span v-text="title" />
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",

  computed: {
    title() {
      return this.$route.name;
    },
  },
};
</script>
