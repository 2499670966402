<template>
  <div>
    <form-title :text="$t('title.select_sign')" />
    <form-content>
      <div class="flex flex-wrap -mx-1">
        <div
          class="px-1 w-1/3 lg:w-1/4"
          v-for="item in zodiacSigns"
          :key="item.name"
        >
          <button
            class="rounded w-full h-full p-2 flex flex-col justify-center items-center appear group"
            @click="$emit('update-user-data', { zodiacSign: item.name })"
          >
            <div
              class="rounded-full w-18 h-18 sm:w-20 sm:h-20 flex items-center justify-center mb-1 text-red-500 transition-color  duration-1000 group-hover:text-yellow-500"
            >
              <inline-svg
                :src="item.thumb"
                class="fill-current h-16 sm:h-18 w-auto"
              />
            </div>
            <span class="font-headings">
              {{ item.name }}
            </span>
            <span
              class="font-headings italic text-xs transition-opacity duration-500 opacity-0 group-hover:opacity-75 hidden lg:block"
            >
              {{ item.startDate | formatDate }} /
              {{ item.endDate | formatDate }}
            </span>
          </button>
        </div>
      </div>
    </form-content>
  </div>
</template>

<script>
import FormContent from "./FormContent.vue";
import FormTitle from "./FormTitle.vue";
import i18n from "@/i18n.js";
export default {
  components: { FormTitle, FormContent },
  name: "SelectSign",
  created() {
    this.$store.commit("updateUserData", {
      birthDay: "",
      birthMonth: "",
      birthDecade: "",
      birthYear: "",
    });
  },

  computed: {
    zodiacSigns() {
      return this.$store.state.zodiacSigns;
    },
  },

  filters: {
    formatDate: function(value) {
      if (!value) return "";
      const [month, day] = value.split(".");
      value = new Date(2000, month - 1, day, 0, 0, 0);

      return value.toLocaleString(i18n.t("date_format"), {
        day: "numeric",
        month: "short",
      });
    },
  },
};
</script>
