<template>
  <container>
    <breadcrumbs />
    <router-view />
  </container>
</template>

<script>
// TODO: some shit here that has to be deleted after we decide how to manage these static pages
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Container from "../components/Ui/Container.vue";

export default {
  name: "Static",
  components: {
    Breadcrumbs,
    Container,
  },
};
</script>
